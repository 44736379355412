import React from "react"
import Helmet from "react-helmet"
import { Link } from 'gatsby'
import Layout from "../components/layout"

class TutorialPage extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <div className="content">
          <Helmet>
            <title>知识库</title>
          </Helmet>
          <div className="content">
            <h1>睡吧知识库</h1>
            睡吧的存在是为了帮助每个被失眠困扰的朋友。面对失眠，绝大多数朋友的理解都是错的。希望每一个失眠的朋友都能记住两个问题的答案：

            <ul>
              <li>什么是失眠？<strong>失眠不是病，而是自己身体和心理状态的一种表现。我们应该去改善自身存在的问题，而不是去“治疗”失眠本身</strong>。 </li>
              <li>怎样才算恢复？<strong>恢复正常睡眠并不表示已经恢复。睡不好的情况下，依然能做好平常事，依然能过好每一天，才算是恢复。</strong></li>
            </ul>

            <h2>如何对治失眠？</h2>

            因为失眠不是病，所以我们不去对治失眠，而是解决让你陷入失眠的根本原因。一般从失眠中走出通常需要两个步骤：

            <ul>
              <li><strong>解决造成失眠的原因</strong>。 通常失眠源自失去平衡的生活。而解决失眠就需要恢复正常的生活节奏。</li>
              <li><strong>解决无法从失眠中走出的原因</strong>。 无法从失眠中走出的最主要原因是被失眠带来的负面感受所左右，一方面会为失眠做很多事情，另一方面为失眠放弃了很多事情。所以我们必须停止被这些情绪所左右，让自己专注于生活本身。 </li>
            </ul>


            <h2>想要求助？</h2>

            请进行<Link to="/evaluate/login">自助睡眠评估</Link>。如果睡眠评估无法给你满意的结果，请发表评估内容到<a href="http://www.douban.com/group/hellosleep/">睡吧社区</a>进行求助。
          </div>
        </div>
      </Layout>
    )
  }
}

export default TutorialPage
